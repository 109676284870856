@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import './prism.css';
@import 'tailwindcss/utilities';

/* s Playfair (Normal),
Alegreya (400) eller Merriweather?  */

/*  @font-face {
    font-family: Prata;
    src: url('/fonts/prata-regular-webfont.woff2');
    format: ('woff2');
    font-display: swap;
  } */
@layer base {
  @font-face {
    font-family: Raleway regular;
    src: url('/fonts/Raleway-Regular.woff2');
    format: ('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: DMSans regular;
    src: url('/fonts/DMSans-Regular.woff2');
    format: ('woff2');
    font-display: swap;
  }

  body {
    overscroll-behavior: none;
  }
}

[class^='number-slide'],
[class*=' number-slide'] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.hamburger .line {
  height: 2px;
  display: block;
  margin: 6px auto;
  transition: all 0.3s ease-in-out;
}

/* ONE */

#hamburger-1.is-active .line:nth-child(3) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(2) {
  transform: translateY(0px) rotate(-45deg);
}
.background-rotation {
  height: 200px;
  position: relative;
  justify-content: center;
  color: #fff;
  background-size: -100% -200%;
}

.background-rotation * {
  user-select: none;
  -webkit-user-select: none;
}

.background-rotation__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 200%;
  color: rgb(98, 105, 105);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: block;
  text-align: center;
  line-height: 200px;
}

.background-rotation__inner span {
  font-size: 30px;
  font-weight: bold;
}

.chevron {
  position: absolute;
  width: 1.2rem;
  height: 0.21rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}
.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #eaf0f6;
}
.chevron:before {
  left: 0;
  transform: skewY(30deg);
}
.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}
@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

.videoWrapper {
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.fluidVideoBackground {
  object-fit: cover;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: auto !important;
  width: 100% !important;
  bottom: auto !important;
}

.secondaryMenuItemIn {
  animation: fadeIn 700ms ease-in-out;
  animation-delay: 800ms;
  animation-fill-mode: forwards;
}

.primaryMenuItemIn li {
  opacity: 0;
  animation-delay: 1000ms;
  animation: fadeIn 500ms ease-in-out;
  animation-fill-mode: forwards;
}

.primaryMenuItemIn li:nth-child(1) {
  animation-delay: 550ms;
}

.primaryMenuItemIn li:nth-child(2) {
  animation-delay: 600ms;
}

.primaryMenuItemIn li:nth-child(3) {
  animation-delay: 650ms;
}

.primaryMenuItemIn li:nth-child(4) {
  animation-delay: 700ms;
}
.primaryMenuItemIn li:nth-child(5) {
  animation-delay: 750ms;
}

.primaryMenuItemIn li:nth-child(6) {
  animation-delay: 800ms;
}

.primaryMenuItemIn li:nth-child(7) {
  animation-delay: 850ms;
}

.primaryMenuItemIn li:nth-child(8) {
  animation-delay: 900ms;
}
.primaryMenuItemIn li:nth-child(9) {
  animation-delay: 950ms;
}

.primaryMenuItemIn li:nth-child(10) {
  animation-delay: 950ms;
}

.primaryMenuItemOut {
  opacity: 1;
  animation: fadeOut 100ms ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

h1,
h2,
h3 {
}

p {
  letter-spacing: 0rem !important;
}
